$(document).on('turbolinks:load', () => {
  // function init() {
  //   var px_change = 650;

  //   window.addEventListener("scroll", function (e) {
  //     if ($(window).scrollTop() > px_change) {
  //       $(".header_btn .search_box").css({ opacity: "1" });

  //     } else if ($(window).scrollTop() < px_change) {
  //       $(".header_btn .search_box").css({ opacity: "0" });
  //     }
  //   });
  // }
  // init();
  
  // $(".slider").slick({
  //   slidesToShow: 1,
  //   slidesToScroll: 1,
  //   autoplaySpeed: 3000,
  //   // 画像下のドット（ページ送り）を表示
  //   arrows: false,
  //   dots: false,
  //   autoplay: true,
  //   speed: 1500,
  //   fade: true,
  //   pauseOnFocus: false,
  //   pauseOnHover: false,
  //   pauseOnDotsHover: false,
  // });
});